import React from "react"
import { Link } from "gatsby"
import Finance from "../../assets/img/industries/finance.png"
import Healthcare from "../../assets/img/industries/healthcare.png"
import Retail from "../../assets/img/industries/retail.png"
import Outsourcing from "../../assets/img/industries/outsourcing.png"
import Construction from "../../assets/img/industries/construction.png"
import Estate from "../../assets/img/industries/estate.png"
import Styled from "./styled.module.css"

function Industries() {
  return (
    <section className={Styled.section} id="section-2">
      <div className="container">
        <div className={Styled.wrapper}>
          <div className={Styled.card}>
            <h1 className="md-title bold">
              Our expertise across<br /> multiple industries
            </h1>
            <div className={Styled.cardTextWrapper}>
              <p className="md-text">
                We have probably done some work in a business process critical to your industry. We deliver fast results with the abiliy to scale.
              </p>
              <Link className="explore xs-text bold " to="/industries">
                Explore all industries
              </Link>
            </div>
          </div>
          <div className={Styled.imgWrapper}>
            <Link
              className={Styled.cardText}
              to="/industries/accountancy-finance"
            >
              <div className={Styled.overlay}>
                <img alt="Finance" className={Styled.img} src={Finance}></img>
                <div className={Styled.overlayWrapper}>
                  <div>
                    <h1 className={`${Styled.cardTitle} lg-text bold`}>
                      Accounting<br />
                      & Finance
                  </h1>
                  </div>
                </div>
              </div>
            </Link>
            <Link
              className={Styled.cardText}
              to="/industries/property-management"
            >
              <div className={Styled.overlay}>
                <img alt="Estate" className={Styled.img} src={Estate}></img>
                <div className={Styled.overlayWrapper}>
                  <div>
                    <h1 className={`${Styled.cardTitle} lg-text bold`}>
                      Property management
                  </h1>
                  </div>
                </div>
              </div>
            </Link>
            <Link className={Styled.cardText} to="/industries/construction">
              <div className={Styled.overlay}>
                <img alt="Construction" className={Styled.img} src={Construction}></img>
                <div className={Styled.overlayWrapper}>
                  <div>
                    <h1 className={`${Styled.cardTitle} lg-text bold`}>Construction</h1>
                  </div>
                </div>
              </div>
            </Link>
            <Link
              className={Styled.cardText}
              to="/industries/outsourcing"
            >
              <div className={Styled.overlay}>
                <img
                  alt="Construction Industry"
                  className={Styled.img}
                  src={Outsourcing}
                ></img>
                <div className={Styled.overlayWrapper}>
                  <div>
                    <h1 className={`${Styled.cardTitle} lg-text bold`}>
                      Business<br />Process<br />Outsourcing
                  </h1>
                  </div>
                </div>
              </div>
            </Link>
            <Link
              className={Styled.cardText}
              to="/industries/retail"
            >
              <div className={Styled.overlay}>
                <img alt="eCommerce" className={Styled.img} src={Retail}></img>
                <div className={Styled.overlayWrapper}>
                  <div>
                    <h1 className={`${Styled.cardTitle} lg-text bold`}>
                      Retail
                  </h1>
                  </div>
                </div>
              </div>
            </Link>
            <Link className={Styled.cardText} to="/industries/professional-services">
              <div className={Styled.overlay}>
                <img
                  alt="Healthcare"
                  className={Styled.img}
                  src={Healthcare}
                ></img>
                <div className={Styled.overlayWrapper}>
                  <div>
                    <h1 className={`${Styled.cardTitle} lg-text bold`}>
                      Professional services
                  </h1>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div >
      </div >
    </section >
  )
}

export default Industries
