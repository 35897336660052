import React from "react"
import Styled from "./styled.module.css"
import BlogThumbnail from "../../assets/img/integra-blog.png"
import BlogThumbnail2 from "../../assets/img/integra-blog2.png"
import BlogThumbnail3 from "../../assets/img/integra-blog3.png"
import Avatar1 from "../../assets/img/leader/aravind-bg.png"
import Avatar2 from "../../assets/img/leader/mellwyn-bg.png"
import Element from "../../assets/img/element.svg"

function Organisms() {
  return (
    <section className={Styled.section} id="organisms">
      <div className={`${Styled.organismsWrapper} container`}>
        <div className={Styled.articles}>
          <div className={Styled.articleText}>
            <div>
              <h1 className="md-title bold">Latest Articles</h1>
              <div className={Styled.divider}></div>
            </div>
            <a className="explore hidden-upto-sm" href="https://www.globalintegra.com/blog/" target="_blank">
              Visit our blog
            </a>
          </div>
        </div>
        <div className={Styled.cardWrapper}>
          <div className={Styled.card}>
            <img
              alt="Card Element"
              className={Styled.cardElement}
              src={Element}
            ></img>
            <div className={Styled.cardImage}>
              <img alt="Laptop" src={BlogThumbnail}></img>
            </div>
            <div className={Styled.cardDetail}>
              <div className={Styled.cardLabel}>
                <span>COMPANY</span>
              </div>
              <h1 className={Styled.cardTitle}>
                Integra Global Solutions Corp Rebranding
              </h1>
              <p className={Styled.cardContent}>
                We are proud to announce the launch of our new logo as part of expansion of our services and to solidify our stance as an established corporate.
              </p>
              <div className={Styled.userDetail}>
                {/* <div className={Styled.avatar}>
                  <img alt="Avatar1" src={Avatar1} />
                  <span className={Styled.user}>Aravind Kumar</span>
                </div> */}
                <a target="_blank" href="https://www.globalintegra.com/blog/?p=2271" className={Styled.readMore}>
                  Read more 🡢
                </a>
              </div>
            </div>
          </div>
          <div className={Styled.card}>
            <div className={Styled.cardImage}>
              <img alt="Laptop" src={BlogThumbnail2}></img>
            </div>
            <div className={Styled.cardDetail}>
              <div className={Styled.cardLabel}>
                <span>RPA</span>
              </div>
              <h1 className={Styled.cardTitle}>
                RPA – Driving Next-generation Accounting
              </h1>
              <p className={Styled.cardContent}>
                Throughout the BFSI sector, there’s a dramatic change in the office of the CFO to really focus on being strategic and bringing strategic advice...
              </p>
              <div className={Styled.userDetail}>
                {/* <div className={Styled.avatar}>
                  <img alt="Avatar1" src={Avatar2} />
                  <span className={Styled.user}>Glen Williams</span>
                </div> */}
                <a target="_blank" href="https://www.globalintegra.com/blog/?p=2254" className={Styled.readMore}>
                  Read more 🡢
                </a>
              </div>
            </div>
          </div>
          <div className={Styled.card}>
            <div className={Styled.cardImage}>
              <img alt="Laptop" src={BlogThumbnail3}></img>
            </div>
            <div className={Styled.cardDetail}>
              <div className={Styled.cardLabel}>
                <span>AUTOMATION</span>
              </div>
              <h1 className={Styled.cardTitle}>
                SMM starter pack, part 2: content promotion
              </h1>
              <p className={Styled.cardContent}>
                IWe continue the conversation between Rob, Senior Manager – IT Automation, Sara, Senior Director of Business Automation and ...
              </p>
              <div className={Styled.userDetail}>
                {/* <div className={Styled.avatar}>
                  <img alt="Avatar1" src={Avatar2} />
                  <span className={Styled.user}>Glen Williams</span>
                </div> */}
                <a target="_blank" href="https://www.globalintegra.com/blog/?p=2227" className={Styled.readMore}>
                  Read more 🡢
                </a>
              </div>
            </div>
          </div>
          <a className="explore hidden-from-sm j-self-end" href="https://www.globalintegra.com/blog/" target="_blank">
            Visit our blog
          </a>
        </div>
      </div>
    </section>
  )
}

export default Organisms
